import { emptyFn, isLiveEnv } from '@trader/constants';

/**
 * Prevents browser error messages.
 */

const originalConsoleError = console.error;

export const disableConsoleErrors = () => {
  if (isLiveEnv) {
    console.error = emptyFn;
    // console.warn = emptyFn;
    // console.log = emptyFn;
    // console.info = emptyFn;

    console.error = function (...args) {
      if (
        (args[0] && args[0].includes('ResizeObserver loop')) ||
        (args[0] && args[0].includes('Window origin changed')) ||
        (args[0] &&
          args[0].includes(
            'Could not start new record on document.visible event'
          ))
      ) {
        return true; //  Ignore the errors that smartLook triggers
      }
      originalConsoleError.apply(console, args);
    };
  }
};
