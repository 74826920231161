import { useEffect } from 'react';

import { useMst } from '@trader/store';
import { ESocketUpdateAction } from '@trader/types';
import { EConnectionHub, EConnectionSubscription } from '@trader/services';

import { useStartConnection } from './core';

export const usePriceAlertsCreation = () => {
  const store = useMst();

  const { connection } = useStartConnection(
    EConnectionHub.Inboxes,
    EConnectionSubscription.PriceAlertsCreation,
    'inboxes'
  );

  const idToken = store.auth.tokens.idToken || '';

  const handleMessage = (message: {
    id: number;
    updateAction: ESocketUpdateAction;
  }) => {
    if (message.updateAction === ESocketUpdateAction.Delete) {
      store.entities.alerts.getAlertsAsync.run();
    } else {
      store.entities.alerts.getAlertByIdAsync.run(message.id);
    }
  };

  useEffect(() => {
    if (store.auth.isAuth) {
      connection.subscribe(hub => {
        hub.on('OnAlert', handleMessage);
      });
    }
  }, [store.auth.isAuth, idToken]);
};
