import { useEffect } from 'react';

import {
  EConnectionHub,
  EConnectionSubscription,
  HubConnection,
} from '@trader/services';
import { IMessage } from '@trader/types';
import { TAlertEntity, useMst } from '@trader/store';
import { formatByPipSize, getSpreadDifference } from '@trader/utils';
import { getAccountTypeForConnection, productId } from '@trader/constants';

import { useStartConnection } from './core';

export const usePriceAlertsCurrentPrice = () => {
  const store = useMst();

  const { connection, isAppDataLoaded } = useStartConnection(
    EConnectionHub.PriceAlerts,
    EConnectionSubscription.PriceAlertsCurrentPrice,
    'quotes'
  );

  const priceAlerts = store.entities.alerts.getAll<TAlertEntity>();
  const priceAlertsSymbols = priceAlerts.map(
    pa => pa.conditions[0].instrument.symbol
  );
  const uniqueSymbols = [...new Set(priceAlertsSymbols)];

  const handleSubscribe = async (hub: HubConnection) => {
    if (priceAlerts.length > 0 && isAuth && platformLogin && accountType) {
      await hub.send(
        'SubscribeOnQuotes',
        uniqueSymbols,
        1,
        productId[product],
        platformLogin,
        getAccountTypeForConnection[accountType]
      );
      hub.on('OnQuote', handleMessage);
    }
  };

  const idToken = store.auth.tokens.idToken;
  const isAuth = store.auth.isAuth;
  const activeTradingAccount = store.user.tradingAccount;
  const platformLogin = activeTradingAccount?.platformLogin;
  const accountType = activeTradingAccount?.accountType;
  const product = store.user.getAccountProduct();

  function handleMessage(message: IMessage) {
    const symbolPriceAlerts = priceAlerts.filter(
      pa => pa.conditions[0].instrument.symbol === message.s
    );
    if (symbolPriceAlerts.length > 0) {
      for (const priceAlert of symbolPriceAlerts) {
        const { ask, bid } = getSpreadDifference(
          priceAlert.conditions[0].instrument.spreadDiff,
          message.a,
          message.b,
          priceAlert.conditions[0].instrument.spreadDiffBalance,
          priceAlert.conditions[0].instrument.pipSize
        );
        const indicatorType =
          priceAlert.conditions[0].leftExpression.indicatorType;
        const shouldSetBidPrice = indicatorType === 'Bid';
        const priceAlertEntity = store.entities.alerts.get<TAlertEntity>(
          priceAlert.id
        );
        priceAlertEntity.runInAction(() => {
          priceAlertEntity.conditions[0].instrument.currentPrice =
            shouldSetBidPrice
              ? +formatByPipSize(
                  bid,
                  priceAlert.conditions[0].instrument.pipSize
                )
              : +formatByPipSize(
                  ask,
                  priceAlert.conditions[0].instrument.pipSize
                );
        });
      }
    }
  }

  /**
   * Handle subscribe on price alerts symbols.
   */
  useEffect(() => {
    isAppDataLoaded && connection.subscribe(handleSubscribe);

    return () => {
      if (priceAlerts.length > 0 && isAuth && platformLogin && accountType) {
        connection.unsubscribe(async hub => {
          await hub?.send(
            'UnsubscribeFromQuotes',
            uniqueSymbols,
            1,
            productId[product],
            platformLogin,
            getAccountTypeForConnection[accountType]
          );
        });
      }
    };
  }, [priceAlerts.length, platformLogin, idToken, isAppDataLoaded]);
};
