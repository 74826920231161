import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import {
  LOCAL_STORAGE_KEYS,
  MODAL_TYPES,
  pingStrategyInterval,
  strategyCategoryName,
} from '@trader/constants';
import {
  useLayouts,
  useMuliBands,
  useNavigation,
  usePrefetchInformation,
} from '@trader/hooks';
import { localStorageService, useI18next } from '@trader/services';
import { TInstrumentEntity, useMst } from '@trader/store';
import { EChartLayouts } from '@trader/types';
import {
  AdvanceChart,
  AssetDetails,
  BottomBar,
  InstrumentDetailsHeader,
  Modals,
  TopBar,
} from '@trader/containers';

import { InfoBlocks } from './components/infoBlocks';
import { RightMenu } from './components/rightMenu';
import { BackTestStrategyTab } from './components/backTestStrategyTab';

import * as Styled from './styled';

export const MuliBands: React.FC = observer(() => {
  const { translate } = useI18next();
  const store = useMst();
  const { goBackNavigate } = useNavigation();
  usePrefetchInformation();

  const idToken = store.auth.tokens.idToken;

  const backTesting = store.pages.muliBands.backTesting;

  const trading = store.trading.getTrading('createStrategyBand');

  const tradingSymbol = store.pages.trading.getInstrumentSymbolByLayout();
  const muliBandsSymbol = store.pages.muliBands.symbol;
  const symbol = muliBandsSymbol || tradingSymbol;

  const isChangeTAFinished =
    !store.entities.tradingAccounts.changeTradingAccountAsync.inProgress;

  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  const defaultPeriod = store.pages.muliBands.chartPeriod;

  const hasSomeOrders = Array.from(store.pages.muliBands.strips.values()).some(
    s => s.orderId
  );
  const hasSomePositions = Array.from(
    store.pages.muliBands.strips.values()
  ).some(s => s.positionId);

  useMuliBands();
  useLayouts(symbol);

  const handleChangeSymbol = useCallback((value: string) => {
    store.pages.muliBands.runInAction(() => {
      store.pages.muliBands.symbol = value;
    });
    store.pages.muliBands.backTesting.clear();
    store.pages.muliBands.backTesting.cancelBackTestRunningAsync.run();
  }, []);

  useEffect(() => {
    if (!store.user.settings.isXStrategyDisplayedByUser) {
      goBackNavigate();

      if (hasSomeOrders || hasSomePositions) {
        store.ui.modal.open(MODAL_TYPES.muliBandsDisabled);
      }
    }
  }, [
    store.user.settings.isXStrategyDisplayedByUser,
    hasSomeOrders,
    hasSomePositions,
  ]);

  useEffect(() => {
    if (isChangeTAFinished) {
      (async () => {
        const entity =
          store.entities.instruments.get<TInstrumentEntity>(symbol);
        !entity?.minOrderSize &&
          (await store.entities.instruments.getInstrumentSpecificationAsync.run(
            symbol
          ));

        trading.getInstrumentReferenceAsync.run({
          symbol,
          side: null,
        });
      })();
    }

    store.pages.muliBands.getInitialDataAsync.run(symbol);

    const interval = setInterval(() => {
      store.pages.muliBands.pingMuliBandAsync.run();
    }, pingStrategyInterval);

    return () => {
      store.pages.muliBands.deleteMuliBandAsync.run();
      clearInterval(interval);
    };
  }, [symbol, idToken, isChangeTAFinished]);

  useEffect(() => {
    backTesting.existsBackTestAsync.run();
  }, []);

  useEffect(() => {
    store.entities.ordersMetrics.getOrdersMetricsAsync.run({
      shouldClearBeforeMerge: true,
    });
    store.user.getProfileDemoAsync.run();
    store.entities.challenges.getChallengesAsync.run();
    return () => {
      localStorageService.remove(LOCAL_STORAGE_KEYS.tradingViewSettings);
    };
  }, []);

  if (!instrument) {
    return (
      <Styled.Root>
        <TopBar />
        <Styled.Main />
        <BottomBar />
      </Styled.Root>
    );
  }

  return (
    <Styled.Root>
      <TopBar withChartLayoutsSelection={false} />
      <Styled.Body>
        <Styled.Content>
          <Styled.Main>
            <InstrumentDetailsHeader
              onChangeSymbol={handleChangeSymbol}
              isMuliBandsStrategy
              searchCategory={strategyCategoryName}
              searchPlaceholder={'COMMON.LABELS.SEARCH_INSTRUMENT'}
              instrument={{
                ...instrument,
                layoutNumber: EChartLayouts.FirstLayout,
              }}
            />

            {!store.pages.muliBands.getInitialDataAsync.inProgress && (
              <React.Fragment>
                <InfoBlocks />
                <AdvanceChart
                  isMuliBandsStrategy
                  timeframe='1D'
                  extraDisabledFeatures={[
                    'timeframes_toolbar',
                    'header_resolutions',
                    'main_series_scale_menu',
                    'property_pages',
                  ]}
                  defaultPeriod={defaultPeriod}
                  instrumentSymbol={instrument.symbol}
                  layoutNumber={EChartLayouts.FirstLayout}
                />
              </React.Fragment>
            )}
          </Styled.Main>
          <AssetDetails
            forceBeta
            minHeight={345}
            navigationKey='muliBandsView'
            assetPage='muliBands'
            additionalTabs={[
              {
                label: translate('MULI_BANDS.BACKTEST_STRATEGY'),
                value: 'backtest-strategy',
                children: <BackTestStrategyTab />,
              },
            ]}
          />
        </Styled.Content>
        <RightMenu />
      </Styled.Body>
      <BottomBar />
      <Modals />
    </Styled.Root>
  );
});
