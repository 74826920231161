import { useContext, useEffect } from 'react';
import i18next from 'i18next';

import {
  EOrderSide,
  ESocketUpdateAction,
  IOrderMessage,
  TPlaceOrderSide,
} from '@trader/types';
import {
  HubConnection,
  EConnectionHub,
  EConnectionSubscription,
} from '@trader/services';
import {
  getAccountTypeForConnection,
  MODAL_TYPES,
  productId,
} from '@trader/constants';
import { useMst } from '@trader/store';
import { TradingNotificationsContext } from '@trader/contexts';

import { useStartConnection } from './core';

/**
 * Custom hook that handles the subscription to pending orders.
 * It processes incoming order messages, updates the store, and handles modal interactions.
 */
export const usePendingOrders = () => {
  const store = useMst();
  const { handleSaveMessage } = useContext(TradingNotificationsContext);

  const { connection, isAppDataLoaded } = useStartConnection(
    EConnectionHub.Account,
    EConnectionSubscription.PendingOrders,
    'account'
  );

  const idToken = store.auth.tokens.idToken || '';
  const activeTradingAccount = store.user.tradingAccount;
  const platformLogin = activeTradingAccount?.platformLogin || '';
  const accountType = activeTradingAccount?.accountType || '';
  const product = store.user.getAccountProduct();

  const handleSubscribe = async (hub: HubConnection) => {
    if (platformLogin && accountType) {
      await hub.send(
        'SubscribeOnOrders',
        platformLogin || null,
        productId[product],
        getAccountTypeForConnection[accountType]
      );
      hub.on('order', handleMessage);
    }
  };

  const getStaticModalOptions = (message: IOrderMessage) => {
    const billType: TPlaceOrderSide =
      EOrderSide.Buy === message.side ? 'Buy' : 'Sell';

    return {
      orderId: message.id,
      symbol: message.symbol,
      price: message.limitPrice || message.stopPrice,
      billType,
      volume: message.volume,
    };
  };

  const handleOrderPlaced = (message: IOrderMessage) => {
    const isOrderPlaced = store.ui.modal.options.get('isOrderPlaced');

    if (isOrderPlaced) {
      store.ui.modal.open(MODAL_TYPES.orderResponse, {
        ...getStaticModalOptions(message),
        isClose: false,
      });
    }
    store.entities.ordersMetrics.getOrdersMetricsAsync.run();
  };

  const handleOrderCancelled = (message: IOrderMessage) => {
    const orderId = store.ui.modal.options.get('orderId');
    const isOrderCanceled = store.ui.modal.options.get('isOrderCanceled');
    const skipIdCheck = store.ui.modal.options.get('skipOrderIdCheck');

    // The check needed for the case when the position is closed
    // and the order DELETE action is triggered too.
    const isIdMatched = skipIdCheck
      ? true
      : Number(orderId) === Number(message.id);
    if (isIdMatched && isOrderCanceled) {
      store.ui.modal.open(MODAL_TYPES.orderResponse, {
        ...getStaticModalOptions(message),
        isClose: true,
      });
    }
    store.entities.ordersMetrics.delete(message.id);
  };

  const handleOrderUpdated = (message: IOrderMessage) => {
    store.entities.ordersMetrics.update(message.id, {
      quantity: message.volume,
      limitPrice: message.limitPrice,
      stopPrice: message.stopPrice,
      stopLoss: message.stopLoss,
      takeProfit: message.takeProfit,
    });
  };

  const handleOrderEdited = (message: IOrderMessage) => {
    const orderId = store.ui.modal.options.get('orderId');
    const isOrderEdited = store.ui.modal.options.get('isOrderEdited');

    if (Number(orderId) === Number(message.id) && isOrderEdited) {
      store.ui.modal.close();
      store.ui.modal.update({});
      store.notifications.add({
        message: i18next.t('NOTIFICATIONS.ORDER_HAS_BEEN_EDITED'),
        options: {
          variant: 'success',
        },
      });
    }
  };

  const handleMessage = (message: IOrderMessage) => {
    const isBetaDesign = store.app.isBetaDesignEnabled();

    switch (message.updateAction) {
      case ESocketUpdateAction.Create:
        isBetaDesign
          ? handleSaveMessage({ type: 'order', ...message })
          : handleOrderPlaced(message);
        break;
      case ESocketUpdateAction.Delete:
        isBetaDesign
          ? handleSaveMessage({ type: 'order', ...message })
          : handleOrderCancelled(message);
        break;
      case ESocketUpdateAction.Update:
        isBetaDesign && handleSaveMessage({ type: 'order', ...message });
        !isBetaDesign && handleOrderUpdated(message);
        !isBetaDesign && handleOrderEdited(message);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    isAppDataLoaded && connection.subscribe(handleSubscribe);

    return () => {
      if (platformLogin && accountType) {
        connection.unsubscribe(async hub => {
          await hub?.send(
            'UnsubscribeFromOrders',
            platformLogin || null,
            productId[product],
            getAccountTypeForConnection[accountType]
          );
        });
      }
    };
  }, [platformLogin, idToken, isAppDataLoaded]);
};
